import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import crisis1 from "../../../static/img/crisis/crisis1.jpg"
import crisis2 from "../../../static/img/crisis/crisis2.jpg"
import crisis4 from "../../../static/img/crisis/crisis4.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Crisis Management"/>
            <Banner title="Crisis Management"/>
            <section className="about_consulting_area bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="ex_content">
                                <h3>Client’s profile</h3>

                                <p>A company engaged in the financial services sector
                                    announced its
                                    plan to execute a takeover bid for one of its peers, which was negatively perceived
                                    by
                                    the market, as
                                    the market cap of the acquiring company is lower than the target company. For that
                                    reason, there are
                                    serious doubts about the financing of the transaction, as well as the synergies and
                                    economies of scale
                                    that could be achieved, which in turn leads to a double-digit fall in the acquiring
                                    company’s stock
                                    during a single trading day.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 text-center">
                            <img className="video_img wow fadeInRight" src={crisis1} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area bg-white">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-5">
                            <img className="video_img w-100 wow fadeInLeft" src={crisis2} alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h3>Mandate</h3>

                                <p>For its experienced team in corporate events and
                                    M&As, IRStrat is
                                    engaged as advisor in the acquisition process, contracting its IRStrat II solution
                                    package (“Strategic
                                    Message”) with the mandate to broaden the client’s communication outreach and
                                    sharpen
                                    the acquisition’s
                                    message to convince the market that the latter will help strengthen the client’s
                                    business in the long
                                    run, dispelling the skepticism that negatively impacted the valuation and trading of
                                    our
                                    client’s stock.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-0">
                <div className="container ex_content">
                    <div className="row m-0">
                        <div className="col-md-12 pl-3 pl-lg-0">
                            <h3 className="s_title d-block">Solution</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="executive_area pt-3 pb-4">
                <div className="container">
                    <div className="row mt-3 step service_right_sidebar p-0 mb-5 d-flex align-items-center">
                        <div className="col-lg-7">
                            <div id="accordion7" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion7" href="#collapse25"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse25">
                                                <span className="pluse">+</span><span className="minus">-</span>Strategy
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse25" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body"><p>IRStrat swiftly conducts a
                                            comprehensive survey to gather
                                            the market sentiment about the transaction. Using the information collected,
                                            IRStrat develops and
                                            carries out an express repositioning and communication plan. Afterwards, an
                                            extensive PR agenda is
                                            defined, and a dissemination plan is prepared, consisting on multiple
                                            carefully-crafted messages
                                            to effectively communicate the value created by the transaction (once the
                                            corresponding due
                                            diligence has been thoroughly reviewed with the company’s management).
                                            IRStrat also helps appoint
                                            the executives responsible for providing interviews and communicate timely
                                            and strategic updates
                                            on the transaction.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse26"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse26">
                                                <span className="pluse">+</span><span className="minus">-</span>Tactic
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse26" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">IRStrat executes a swift and comprehensive plan
                                            which includes an
                                            effective exposure oriented towards the most influential financial media
                                            (TV, print and radio).
                                            Additionally, IRStrat conducts presentations to the analysis and promotion
                                            departments of major
                                            brokerages and financial institutions, as well as to portfolio managers of
                                            the most important
                                            investment and pension funds currently holding our client’s stock.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse27"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Results
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse27" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">The drop in our client’s share price reversed,
                                            climbing 15% when
                                            compared to the closing price prior to the public announcement of the
                                            takeover bid; thanks to the
                                            detailed explanation of the benefits of the transaction and its funding
                                            sources.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                            <img className="img-fluid w-100 wow fadeInRight" src={crisis4} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
